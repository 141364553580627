import React from "react";
import styled from "styled-components";
import AboutItem from "./AboutItem";
import { data } from "../../data/AboutData";

const AboutUsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0;
	margin: 80px 4% 0;

	@media screen and (max-width: 960px) {
		flex-direction: column;
		justify-content: center;
	}
`;

const AnchorSpacer = styled.div`
	position: relative;
	top: -80px;
	left: 0;
	height: 1px;
	width: 1px;
	/* background-color: white; */
`;

const LeftColumn = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	color: #212529;
	padding: 0;
`;

const Title = styled.h3`
	font-size: 40px;
	margin: 0;

	@media screen and (max-width: 960px) {
		font-size: 30px;
		text-align: center;
	}
`;

const Subtitle = styled.p`
	font-size: 20px;
	font-weight: bold;
	margin: 5px 0 20px 0;

	@media screen and (max-width: 960px) {
		text-align: center;
		font-weight: 400;
	}
`;

// const Button = styled.span`
// 	align-self: start;
// 	color: #212529;
// 	font-size: 14px;
// 	font-weight: bold;
// 	padding: 10px 10px;
// 	margin: 0 0 20px 0;
// 	background-color: #fdd803;
// 	border: none;
// 	outline: none;
// 	border-radius: 5px;
// 	cursor: pointer;

// 	@media screen and (max-width: 960px) {
// 		align-self: center;
// 	}
// `;

const RightColumn = styled.div`
	flex: 2;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	margin: 0;

	@media screen and (max-width: 960px) {
	}
`;

const AboutUs = () => {
	return (
		<AboutUsContainer>
			<AnchorSpacer id="about"></AnchorSpacer>
			<LeftColumn>
				<Title>About Us</Title>
				<Subtitle>Unearthing Gold, Embracing Legacy: Our Journey in Mining Excellence</Subtitle>
				{/* <Button>Learn More {"->"}</Button> */}
			</LeftColumn>
			<RightColumn>
				{data.map((item) => {
					return <AboutItem key={item.id} heading={item.heading} paragraph={item.paragraph} />;
				})}
			</RightColumn>
		</AboutUsContainer>
	);
};

export default AboutUs;
