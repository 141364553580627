import React from "react";
import styled from "styled-components";
import ServiceItem from "./ServiceItem";
import { data } from "../../data/ServicesData";

const OurServicesContainer = styled.div`
	margin: 0 4% 0;
`;
const AnchorSpacer = styled.div`
	position: relative;
	top: -80;
	left: 0;
	height: 1px;
	width: 1px;
	margin: 0px 0 80px; /* margin bottom pushes container into view after link click page scroll */
	/* background-color: white; */
`;

const Title = styled.h3`
	color: #212529;
	font-size: 40px;
	margin: 0;
	@media screen and (max-width: 960px) {
		font-size: 30px;
		text-align: center;
	}
`;

const Subtitle = styled.p`
	color: #212529;
	font-size: 20px;
	margin: 5px 0 30px 0;
	@media screen and (max-width: 960px) {
		text-align: center;
	}
`;

const ServicesItemContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
`;

const OurServices = () => {
	return (
		<OurServicesContainer>
			<AnchorSpacer id="services"></AnchorSpacer>
			<Title>Our Services</Title>
			<Subtitle>
				Identifying and developing new territories with high gold potential, ensuring the
				sustainability of our resources.
			</Subtitle>
			<ServicesItemContainer>
				{data.map((item) => {
					return (
						<ServiceItem key={item.id} heading={item.heading} paragraph={item.paragraph} />
					);
				})}
			</ServicesItemContainer>
		</OurServicesContainer>
	);
};

export default OurServices;
