import React, { useEffect } from "react";
import styled from "styled-components";

const MainContainer = styled.div`
	margin-top: 40px;
`;

const HeadingText = styled.h1`
	text-align: center;
`;

const PrivacyPolicy = () => {
	useEffect(() => {
		document.title = "Privacy Policy";
	}, []);

	return (
		<MainContainer>
			<HeadingText>Privacy Policy</HeadingText>
		</MainContainer>
	);
};

export default PrivacyPolicy;
