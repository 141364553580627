import React from "react";
import styled from "styled-components";

const ServiceItemContainer = styled.div`
	color: #fdd803;
	font-size: 20px;
	width: 30%;
	padding: 25px;
	margin: 0 0 35px 0;
	border-radius: 10px;
	background-color: #18181b;
	box-shadow: 0 0 10px #18181b;

	@media screen and (max-width: 960px) {
		width: 65%;
		align-self: center;
	}
	@media screen and (max-width: 760px) {
		width: 100%;
		align-self: center;
	}
`;

const Heading = styled.h4`
	font-size: 20px;
	margin: 0 0 10px 0;
`;

const TextContent = styled.p`
	font-size: 14px;
	margin: 0 0 15px 0;
`;

// const Button = styled.span`
// 	color: #000000;
// 	font-size: 15px;
// 	font-weight: bold;
// 	padding: 10px 10px;
// 	background-color: #fdd803;
// 	border: none;
// 	outline: none;
// 	border-radius: 5px;
// 	cursor: pointer;
// `;

const ServiceItem = (props) => {
	return (
		<ServiceItemContainer>
			<img src={props.icon} alt="" />
			<Heading>{props.heading}</Heading>
			<TextContent>{props.paragraph}</TextContent>
			{/* <Button>
				<a href={props.link}>More Info</a>
			</Button> */}
		</ServiceItemContainer>
	);
};

export default ServiceItem;
