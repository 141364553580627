// import React, { useState } from "react";
import styled from "styled-components";
// import axios from "axios";

// Set API URL address(remote/local):
// const server_address = "http://localhost";
// const server_address = "https://infinite-aurum-email-server.onrender.com";

const HeroSectionContainer = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	background: url(./hero_img_2.jpg);
	background-size: cover;
	height: 100vh;

	@media screen and (max-width: 800px) {
		height: 100svh;
		background: url(./hero_img_mobile_2.png);
	}
`;

const HeroHeading = styled.h1`
	font-size: 45px;
	padding: 0;
	margin: 0 4% 20px;
	@media screen and (max-width: 960px) {
		font-size: 36px;
	}
`;

const Paragraph = styled.p`
	font-size: 21px;
	/* text-align: center; */
	margin: 0 auto 20px;
	max-width: 850px;
	@media screen and (max-width: 960px) {
		font-size: 18px;
		padding: 0 20px;
	}
`;

// const SubscribeContainer = styled.div`
// 	display: flex;
// 	margin: 20px 4% 0;
// 	justify-content: center;
// 	align-items: center;
// 	/* background-color: red; */
// 	@media screen and (max-width: 960px) {
// 		flex-direction: column;
// 	}
// `;

// const InputField = styled.input`
// 	width: 35%;
// 	padding: 10px 10px;
// 	border: solid 2px #fdd803;
// 	border-radius: 10px;
// 	&:focus {
// 		outline: none;
// 	}
// 	@media screen and (max-width: 960px) {
// 		width: 100%;
// 		padding: 8px 10px;
// 	}
// `;

// const ContactButton = styled.input`
// 	width: fit-content;
// 	font-weight: 500;
// 	padding: 12px 15px;
// 	margin: 0 0 0 5px;
// 	background-color: #fdd803 !important;
// 	border: none;
// 	border-radius: 10px;
// 	box-shadow: 0 2px 5px #18181b;
// 	@media screen and (max-width: 960px) {
// 		width: 100%;
// 		margin: 15px 0 0 0px;
// 		padding: 10px 15px;
// 	}
// `;

const HeroSection = () => {
	// const [inputValue, setInputValue] = useState("");
	// const [status, setStatus] = useState("");

	// const handleChange = (e) => {
	// 	const value = e.target.value;
	// 	setInputValue(value);
	// 	setStatus("");
	// };

	// const handleSubmit = async (e) => {
	// 	// Check if inputed email text is a valid email address
	// 	// If invalid email address inputted, alert the user to input a valid email address.
	// 	// If valid email address inputted, send inputted email text to database to be saved.
	// 	e.preventDefault();
	// 	setStatus("Adding new subscriber to email list...");
	// 	try {
	// 		await axios.post(`${server_address}/add-subscriber`, { subscriber_address: inputValue });
	// 		setStatus("Email address sent successfully");
	// 		setInputValue(""); // Clear the form
	// 	} catch (error) {
	// 		console.error("Error sending email:", error);
	// 		setStatus("Failed to add new email to subscriber list. Please try again.");
	// 	}
	// };

	return (
		<HeroSectionContainer>
			<HeroHeading>
				<span style={{ color: "#fdd803", fontStyle: "italic" }}>Redefining</span> the Gold
				Standard
			</HeroHeading>
			<Paragraph>
				Infinite Aurum Limited bridges the gap between gold producers and international
				consumers, providing reliable and high quality gold Bullion.
			</Paragraph>
			{/* <SubscribeContainer>
				<InputField
					type="email"
					name="email"
					placeholder="Enter your email address"
					value={inputValue}
					onChange={handleChange}
					required
				/>
				<ContactButton type="submit" value="Join Email List" onClick={handleSubmit} />
			</SubscribeContainer>
			{status && <p>{status}</p>} */}
		</HeroSectionContainer>
	);
};

export default HeroSection;
