export const data = [
	{
		id: 1,
		icon: "",
		heading: "GOLD TRADING",
		paragraph:
			"Buy, sell, and trade gold with confidence through our secure and intuitive platform, featuring live market prices and our expert insights. Our personalized support team is here to help you navigate the gold market and achieve your trading goals.",
		link: "",
	},
	{
		id: 2,
		icon: "",
		heading: "GOLD REFINERY AND EXPORT",
		paragraph:
			"Our gold refinery services transform raw gold into high-purity products, while our export solutions streamline logistics and compliance for efficient global delivery. Maximize your gold's value with our expert refining and competitive export pricing, backed by personalized support and guidance.",
		link: "",
	},
	{
		id: 3,
		icon: "",
		heading: "GOLD MINING",
		paragraph:
			"Our gold mining services provide expert extraction and processing solutions, from prospecting and exploration to production and reclamation, ensuring maximum yield and minimal environmental impact. With cutting-edge technology and experienced professionals, we help you tap into gold reserves efficiently and responsibly, driving profitability and growth.",
		link: "",
	},
	{
		id: 4,
		icon: "",
		heading: "GOLD MINING CONSULTANT",
		paragraph:
			"Our gold mine consultancy services in Ghana provide expert guidance on exploration, extraction, and operation optimization, helping local and international clients navigate the country's rich gold mining landscape. With in-depth knowledge of Ghana's regulatory environment and mining best practices, we ensure our clients achieve compliant, efficient, and profitable gold mining operations.",
		link: "",
	},
	{
		id: 5,
		icon: "",
		heading: "MINING SUPPORT SERVICES",
		paragraph:
			"Our mining support services provide comprehensive assistance to mining operations, including logistics, equipment maintenance, and technical expertise, to ensure efficient and safe extraction of minerals. From drilling and blasting to transportation and camp management, our tailored solutions help mining companies optimize productivity, reduce costs, and improve overall performance.",
		link: "",
	},
	{
		id: 6,
		icon: "",
		heading: "IMPORT OF MINING AND EXTRACTION EQUIPTMENT",
		paragraph:
			"We facilitate the importation of high-quality mining and extraction equipment, ensuring timely and cost-effective delivery of necessary machinery and tools to support your mining operations. Our expertise in international trade, logistics, and regulatory compliance enables us to streamline the import process, helping you acquire the equipment you need to drive productivity and profitability.",
		link: "",
	},
];
