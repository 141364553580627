import React from "react";
import styled from "styled-components";

const FooterSectionContainer = styled.div`
	color: #fdd803;
	background-color: #18181b;
	padding: 0 0 50px 0;
	margin: 100px 0 0 0;
`;

const ColumnContainer = styled.div`
	display: flex;
	margin: 0 4%; /* left & right margins */

	@media screen and (max-width: 760px) {
		flex-direction: column;
	}
`;

const Column1 = styled.div`
	flex: 3;
	display: flex;
	margin: 30px 0 0 0;
	height: fit-content;

	@media screen and (max-width: 760px) {
		justify-content: center;
	}
`;

const Column2 = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	margin: 30px 0 0 0;

	@media screen and (max-width: 760px) {
		flex-direction: row;
		margin: 10px 0 0 0;

		/* justify-content: space-between; */
	}
`;

const Logo = styled.img`
	width: 40px;
	height: 30px;
	margin: 0 5px 0 0;
`;

const BusinessName = styled.p`
	margin: 0 0 0 0;
	align-self: center;
`;

const ColumnTitle = styled.p`
	color: #ffffff;
	margin: 0 0 10px 0;

	@media screen and (max-width: 760px) {
		text-align: center;
		margin: 0 0 0 0;
		flex: 2;
		font-size: 14px;
	}
`;

const FooterText = styled.p`
	margin: 0 0 5px 0;
	@media screen and (max-width: 760px) {
		flex: 1;
		font-size: 14px;
	}
`;

const FooterLink = styled.a`
	color: #fdd803;
	text-decoration: none;
	&:hover {
		color: white;
	}
`;

const FooterSection = () => {
	return (
		<FooterSectionContainer>
			<ColumnContainer>
				<Column1>
					<Logo src="./logo_main.png" alt="" />
					<BusinessName>Unlimited Aurum LTD © {2024}</BusinessName>
				</Column1>

				<Column2>
					<ColumnTitle>Company</ColumnTitle>
					<FooterText>
						<FooterLink href="#services">Services</FooterLink>
					</FooterText>
					<FooterText>
						<FooterLink href="#about">About</FooterLink>
					</FooterText>
					{/* <FooterText>
						<FooterLink href="#team">Team</FooterLink>
					</FooterText> */}
					{/* <FooterText>
						<FooterLink href="#contact">Contact</FooterLink>
					</FooterText> */}
					{/* <FooterText>
						<FooterLink href="/charity" target="_blank" rel="noopener noreferrer">
							Charity
						</FooterLink>
					</FooterText> */}
				</Column2>
				<Column2>
					<ColumnTitle>Legal</ColumnTitle>
					{/* <FooterText>
						<FooterLink href="/privacy-policy" target="_blank" rel="noopener noreferrer">
						Privacy Policy
						</FooterLink>
						</FooterText> */}
					<FooterText>Privacy Policy</FooterText>
					<FooterText>Terms of Use</FooterText>
					<FooterText>Cookie Policy</FooterText>
				</Column2>
			</ColumnContainer>
		</FooterSectionContainer>
	);
};

export default FooterSection;
