import React from "react";
import styled from "styled-components";

const AboutItemContainer = styled.div`
	color: #18181b;
	font-size: 20px;
	width: 40%;
	height: fit-content;
	padding: 25px;
	margin: 0 0 35px 0;
	border-radius: 10px;
	background-color: #f4f4f4;

	@media screen and (max-width: 960px) {
		margin: 0 0 35px 0;
		width: 65%;
	}

	@media screen and (max-width: 760px) {
		text-align: center;
		width: 100%;
	}
`;

const Icon = styled.img`
	/* Add any styles for the icon here */
`;

const Title = styled.h4`
	font-size: 20px;
	margin: 0;
`;

const TextContent = styled.p`
	font-size: 15px;
`;

const AboutItem = (props) => {
	return (
		<AboutItemContainer>
			<Icon src={props.icon} alt="" />
			<Title>{props.heading}</Title>
			<TextContent>{props.paragraph}</TextContent>
		</AboutItemContainer>
	);
};

export default AboutItem;
