import Carousel from "react-bootstrap/Carousel";
import img1 from "../../media/img1.jpg";
import img2 from "../../media/img2.jpg";
import img3 from "../../media/img3.jpg";
import styled from "styled-components";

const CarouselSectionContainer = styled.div`
	margin: 80px 4% 0;
	display: flex;
	flex-direction: column;
	/* background-color: red; */
`;

const Title = styled.h3`
	color: #212529;
	font-size: 40px;
	margin: 0;
	align-self: center;
	@media screen and (max-width: 960px) {
		font-size: 30px;
		text-align: center;
	}
`;

const Subtitle = styled.p`
	color: #212529;
	font-size: 20px;
	margin: 5px 0 30px 0;
	align-self: center;
	@media screen and (max-width: 960px) {
		text-align: center;
	}
`;

const ImageItem = styled.img`
	width: 80vw;
	border-radius: 10px;

	@media screen and (max-width: 960px) {
		border-radius: 0;
		height: 80vh;
		width: auto;
		justify-content: center;
		border-radius: 10px;

		@media screen and (max-width: 768px) {
			width: 100vw;
			width: auto;
			justify-content: center;
		}
	}
`;

// const CarouselTitle = styled.h3`
// 	color: #ffd803;
// 	@media screen and (max-width: 960px) {
// 		font-size: 30px;
// 		text-align: center;
// 	}
// `;

// const CarouselText = styled.p`
// 	color: #ffd803;
// `;

function ControlledCarousel() {
	return (
		<CarouselSectionContainer>
			<Title>Gallery</Title>
			<Subtitle>Images of Our Various Sites and Sustainable Mining Activities.</Subtitle>
			<Carousel style={{ margin: "0 auto" }} className="carousel-slide">
				<Carousel.Item>
					<ImageItem src={img1} alt="Carousel" />
					{/* <Carousel.Caption>
						<CarouselTitle>First slide Title</CarouselTitle>
						<CarouselText>
							Nulla vitae elit libero, a pharetra augue mollis interdum.
						</CarouselText>
					</Carousel.Caption> */}
				</Carousel.Item>
				<Carousel.Item>
					<ImageItem src={img2} alt="Carousel" />
					{/* <Carousel.Caption>
						<CarouselTitle>Second slide Title</CarouselTitle>
						<CarouselText>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
						</CarouselText>
					</Carousel.Caption> */}
				</Carousel.Item>
				<Carousel.Item>
					<ImageItem src={img3} alt="Carousel" />
					{/* <Carousel.Caption>
						<CarouselTitle>Third slide Title</CarouselTitle>
						<CarouselText>
							Praesent commodo cursus magna, vel scelerisque nisl consectetur.
						</CarouselText>
					</Carousel.Caption> */}
				</Carousel.Item>
			</Carousel>
		</CarouselSectionContainer>
	);
}

export default ControlledCarousel;
