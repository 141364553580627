export const data = [
	{
		id: 1,
		heading: "Safety Conscious",
		paragraph:
			"Infinite Resources prioritizes the safety of our team with comprehensive training, monitoring, and emergency preparedness protocols.",
	},
	{
		id: 2,
		heading: "Sustainable Mining Practices",
		paragraph:
			"We integrate sustainable practices in our operations, from water conservation to land restoration efforts post-mining.",
	},
	{
		id: 3,
		heading: "Mining Sites",
		paragraph:
			"Infinite Resources operates several mines across the country. Our headquarters is strategically located to oversee multiple operations.",
	},
	{
		id: 4,
		heading: "Gold Quality",
		paragraph:
			"Our refined gold exceeds industry standards for purity, thanks to our rigorous quality control and innovative refining processes.",
	},
];
