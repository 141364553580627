import React from "react";
import styled from "styled-components";

const PriceSectionContainer = styled.div`
	text-align: center;
	color: #212529;
	margin: 70px auto 0;
	@media screen and (max-width: 960px) {
		width: 90%;
	}
`;

const Title = styled.h3`
	font-size: 40px;
	margin: 0 0 25px 0;

	@media screen and (max-width: 760px) {
		font-size: 30px;
	}
`;

const CounterCard = styled.div`
	color: #fdd803;
	display: flex;
	justify-content: center;
	width: 400px;
	margin: 0 auto 0;
	background-color: #18181b;
	border-radius: 10px;
	box-shadow: 0 0 10px #18181b;
	@media screen and (max-width: 960px) {
		width: 100%;
	}
`;

const PriceColumn = styled.div`
	flex: 1;
`;

const AssetPrice = styled.p`
	font-size: 30px;
	margin: 15px 0 5px 0;

	@media screen and (max-width: 760px) {
		font-size: 25px;
	}
`;

const AssetNameText = styled.p`
	font-size: 16px;
	margin: 0 0 25px 0;
`;

const PriceSection = () => {
	return (
		<PriceSectionContainer>
			<Title>Current Market Price</Title>
			<CounterCard>
				<PriceColumn>
					<AssetPrice>$2435</AssetPrice>
					<AssetNameText>Gold/USD</AssetNameText>
				</PriceColumn>

				{/* <PriceColumn>
					<AssetPrice>$234.5</AssetPrice>
					<AssetNameText>Silver/USD</AssetNameText>
				</PriceColumn> */}
			</CounterCard>
		</PriceSectionContainer>
	);
};

export default PriceSection;
