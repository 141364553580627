import React, { useEffect } from "react";

import CharityNavbar from "../components/Navbar/CharityNavbar";
import CharityFooter from "../components/Footer/CharityFooter";

const CharityPage = () => {
	useEffect(() => {
		document.title = "Charity Page";
	}, []);

	return (
		<>
			<CharityNavbar />

			<CharityFooter />
		</>
	);
};

export default CharityPage;
